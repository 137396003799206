<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Select the most appropriate reason for disposing of clean but broken glassware, melting
        point capillaries, pipettes, or glass test tubes in the designated broken glass container
        instead of the regular garbage bin:
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: 'The stockroom is unable to charge students for broken glassware that is thrown away in the regular trash. ',
          value: 'chargingStudents',
        },
        {
          text: 'There is a risk of causing harm to janitorial staff if the broken glass is thrown in with the rest of the trash.',
          value: 'janitorialStaff',
        },
        {
          text: 'The glassware might still have some chemical contamination that requires proper disposal methods.',
          value: 'chemicalContamination',
        },
      ],
    };
  },
};
</script>
